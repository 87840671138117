<template>
  <div>
    <Breadcrumbs main="" title="Daily" />
      <b-row>
        <b-col cols="12"> </b-col>
        <b-col>
          <div>
            <b-card>
              
              <b-table
                stacked="md"
                :items="servicesList"
                :fields="fields"
                striped
                thead-class="d-none"
                v-model="currentItems"
              
              >
              <template #cell(_id)="row"> 
                <h5 class="f-w-700">
                  <span title="Service Provider">
                    {{ sentenceCase(row.item.provider_name)  }}
                  </span>
                  -
                  <span title="Service">
                    {{ sentenceCase(row.item.sname) }}
                  </span>

                  <span>[{{  row.item.total }}]</span>

                  <!-- {{row.item.provider_name +" - "+ row.item.sname +" [" +row.item.total+ "] " }} -->
                </h5>
              </template>
                <template #cell(show_details)="row">
                    
                  <b-button size="sm" @click="toggleDetails(row)" :variant="row.detailsShowing ? 'info' : 'primary' " class="mr-2">
                 
                    {{ row.detailsShowing ? "Hide" : "Show" }} Details
                  </b-button>
                </template>

                <template #row-details="row">
                  <b-card>
                   
<transcation-table :trxid="row.item.sid"></transcation-table>

                    <b-button size="sm" :variant="row.detailsShowing ? 'info' : 'info' " @click="row.toggleDetails()"
                      >Hide Details</b-button
                    >
                  </b-card>
                </template>
              </b-table>
            </b-card>
          </div>
        </b-col>
      </b-row>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
// import LineChart from "@/components/Charts/LineChart";

import { BModal, VBModal, ToastPlugin, BvToast } from "bootstrap-vue";

// Tables
import SocialTrafficTable from "./Dashboard/SocialTrafficTable";
import PageVisitsTable from "./Dashboard/PageVisitsTable";

// Services
import dashbordService from "../../services/DashboardService";
import dailiesService from '../../services/DailyServices'
import ContentView from "./Dashboard/Content.vue";
import ServiceTable from "../views/Dashboard/ServicesTable.vue";
import TranscationTable from './../views/manager/Transcations/TranscationsTable.vue'
export default {
  components: {
    TranscationTable,
    ToastPlugin,
    PageVisitsTable,
    SocialTrafficTable,
    ContentView,
    ServiceTable
  },
  data() {
    return {
      fields: ["_id",  "show_details"],
      items: [
        {
          isActive: true,
          age: 40,
          first_name: "Dickerson",
          last_name: "Macdonald",
        },
        { isActive: false, age: 21, first_name: "Larsen", last_name: "Shaw" },
        {
          isActive: false,
          age: 89,
          first_name: "Geneva",
          last_name: "Wilson",
        },
        { isActive: true, age: 38, first_name: "Jami", last_name: "Carney" },
      ],
      thumbnailUri:
        process.env.VUE_APP_BACKHOST +
        ":" +
        process.env.VUE_APP_BACKPORT +
        "/videos/sr_",
      videoUrl: "",
      userActivateStatus: false,
      addetail: {},
      linkschain: [],
      allLinks: [],
      user: "",
      user_status: false,

currentItems: [],
      servicesList: [],
    };
  },
  
  methods: {
    async init() {
      let listPayload = {}
        console.log("init call")
        let startDate = new Date();
        startDate.setUTCHours(0, 0, 0, 0); // Set time to 00:00:00 UTC
        let endDate = new Date();
        listPayload.sdate = startDate;
        listPayload.edate = endDate;

      let listResponse =  await dailiesService.getlist(listPayload)
      console.log(listResponse)
      if(listResponse.success) {
        this.servicesList = listResponse.data
      }


    },
    
   toggleDetails(row) {
  if (row.detailsShowing) {
    row.toggleDetails()
  } else {
    this.currentItems.forEach(item => {
      this.$set(item, '_showDetails', false)
    })
    this.$nextTick(() => {
      row.toggleDetails()
    })
  }
},
    decodeString(str) {
      var div = document.createElement("textarea");
      div.innerHTML = str;
      var decoded = div.firstChild.nodeValue;

      return decoded;
    },
    maxWidth() {
      return "text-truncate";
    },
    showModal(item) {
      this.addetail = item;
      this.videoUrl = `${process.env.VUE_APP_BACKHOST}:${process.env.VUE_APP_BACKPORT}/videos/sr_${item["_id"]}.mp4`;
    },
    async getChains(id) {
      let data = await dashbordService.chains(id);
      this.linkschain = data.data.map((e) => {
        return { link: e };
      });
    },
    copyText(link) {
      navigator.clipboard.writeText(link);
      this.$notify({
        type: "info",
        message: "Copied to clipboard",
      });
    },
    async getLinksData() {
      try {
        this.allLinks = await dashbordService.lists();
        console.log("all data", this.allLinks);
      } catch (e) {
        console.error("ERROR: getting data");
        console.error(e);
      }
    },
    sentenceCase(str) {
      if (typeof str !== 'string') return str
      return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
    }
  },
  mounted() {
    this.init()
    this.userActivateStatus = window.sessionStorage.getItem("status");

    // if (window.sessionStorage.getItem("role") == "manager") {
    //   // this.$router.push("/dash");
    // } else {
    //   console.log("asds", window.sessionStorage.getItem("role"));
    //   if (
    //     !window.sessionStorage.getItem("role") ||
    //     window.sessionStorage.getItem("role") == "null"
    //   ) {
    //     this.$router.push("/login");
    //   } else {
    //     this.user = window.sessionStorage.getItem("username");
    //     this.user_status = window.sessionStorage.getItem("status");
    //     if (this.user_status == "true") {
    //     }
    //   }
    // }
  },
};
</script>
<style scoped>
.text-blue {
  color: #1d75bc;
}
.table .acClass {
  background-color: red;
}
.table .bTableThStyle {
  background-color: blue !important;
  /* max-width: '100px' !important; */
  text-overflow: ellipsis !important;
}
.table > tbody > tr > td {
  max-width: 350px !important;
  /* text-justify: auto; */
  width: 1000px;
  /* white-space: nowrap; */
  overflow: auto;
  text-overflow: ellipsis;
}
.modal-dialog-1 {
  position: fixed;
  margin: auto;

  height: 100%;
  right: 0px;
}
.modal-content-1 {
  height: 100%;
}
/* .el-table .cell {
  max-width: 150px;
  padding-left: 0px;
  padding-right: 0px;
} */
</style>
